const protocol = window.location.protocol;
const host = window.location.host;
const path = window.location.pathname;

let CORE_API_URL = "https://api.igniteconnex.io/core";
let BASE_URL = "https://api.igniteconnex.io";
let AUX_API_URL = "https://api.igniteconnex.io/aux";
let MONITOR_API_URL = "https://api.igniteconnex.io/monitor";
let KC_IDP_URL = "https://sso.igniteconnex.io";
let KC_IDP_REALM_NAME = "igniteconnex";
let KC_IDP_CLIENT_ID = "dashboard";
let KC_IDP_CLIENT_SCOPES = "email roles openid";
let KC_IDP_REDIRECT_URI =
  "https://api.igniteconnex.io/core/igcnx/dashboard/sso/login";
let KC_IDP_GRAFANA_URL = "https://observe.igniteconnex.io/";
let DOCS_URL = "https://docs.igniteconnex.io/";
let IGNITEQL_URL = "https://devkafka.igniteconnex.io/";
let CONSOLE_URL = "https://console.igniteconnex.io/";

if (
  [
    "qadashboard.igniteconnex.io",
    "qa.igniteconnex.io",
    "qa-igniteconnex-dashboard.azurewebsites.net",
  ].includes(host)
) {
  //QA,Staging
  BASE_URL = "https://qa.igniteconnex.io";
  CORE_API_URL = "https://qa.igniteconnex.io/core";
  AUX_API_URL = "https://qa.igniteconnex.io/aux";
  MONITOR_API_URL = "https://qa.igniteconnex.io/monitor";
  KC_IDP_URL = "https://qakeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "dashboard";
  KC_IDP_REDIRECT_URI =
    "https://qa.igniteconnex.io/core/igcnx/dashboard/sso/login";
  KC_IDP_GRAFANA_URL = "https://qagrafana.igniteconnex.io/";
  DOCS_URL = "https://qadocs.igniteconnex.io/";
  IGNITEQL_URL = "https://devkafka.igniteconnex.io/";
  CONSOLE_URL = "https://qaconsole.igniteconnex.io/";
} else if (
  [
    "devdashboard.igniteconnex.io",
    "dev.igniteconnex.io",
    "dev-ignite-dashboard.azurewebsites.net",
  ].includes(host)
) {
  //Dev
  BASE_URL = "https://dev.igniteconnex.io";
  CORE_API_URL = "https://dev.igniteconnex.io/core";
  AUX_API_URL = "https://dev.igniteconnex.io/aux";
  MONITOR_API_URL = "https://dev.igniteconnex.io/monitor";
  KC_IDP_URL = "https://devkeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "dashboard";
  KC_IDP_REDIRECT_URI =
    "https://dev.igniteconnex.io/core/igcnx/dashboard/sso/login";
  KC_IDP_GRAFANA_URL = "https://devgrafana.igniteconnex.io/";
  DOCS_URL = "https://devdocs.igniteconnex.io/";
  IGNITEQL_URL = "https://devkafka.igniteconnex.io/";
  CONSOLE_URL = "https://devconsole.igniteconnex.io/";
} else if (
  [
    "localhost:3000",
    "host.docker.internal:3000",
    "localhost",
    "local.igniteconnex.io",
  ].includes(host)
) {
  //Local
  BASE_URL = "https://local.igniteconnex.io";
  CORE_API_URL = "http://localhost:1992";
  AUX_API_URL = "http://localhost:1991";
  MONITOR_API_URL = "http://localhost:1994";
  KC_IDP_URL = "https://devkeycloak.igniteconnex.io";
  KC_IDP_REALM_NAME = "igniteconnex";
  KC_IDP_CLIENT_ID = "localdashboard";
  KC_IDP_REDIRECT_URI = "http://localhost:1992/igcnx/dashboard/sso/login";
  KC_IDP_GRAFANA_URL = "https://devgrafana.igniteconnex.io";
  DOCS_URL = "https://devdocs.igniteconnex.io/";
  IGNITEQL_URL = "http://localhost:4000/";
  CONSOLE_URL = "http://localhost:3001";
}

// IDP INFO URLs
export const KEYCLOAK_IDP_URL = KC_IDP_URL;
export const KEYCLOAK_IDP_REALM_NAME = KC_IDP_REALM_NAME;
export const KEYCLOAK_IDP_CLIENT_ID = KC_IDP_CLIENT_ID;
export const KEYCLOAK_IDP_REDIRECT_URI = KC_IDP_REDIRECT_URI;
export const KEYCLOAK_IDP_CLIENT_SCOPES = KC_IDP_CLIENT_SCOPES;
export const SIGNOUT_PAGE_URL = `${protocol}//${host}${path}#/logout`;
export const KEYCLOAK_IDP_AUTH_URL = `${KEYCLOAK_IDP_URL}/realms/${KEYCLOAK_IDP_REALM_NAME}/protocol/openid-connect/auth?client_id=${KEYCLOAK_IDP_CLIENT_ID}&response_type=code&scope=${encodeURIComponent(
  KEYCLOAK_IDP_CLIENT_SCOPES
)}&redirect_uri=${encodeURIComponent(KEYCLOAK_IDP_REDIRECT_URI)}`;
export const KEYCLOAK_LOGOUT_URL = `${KEYCLOAK_IDP_URL}/realms/${KEYCLOAK_IDP_REALM_NAME}/protocol/openid-connect/logout?post_logout_redirect_uri=${encodeURIComponent(
  `${SIGNOUT_PAGE_URL}`
)}&client_id=${KC_IDP_CLIENT_ID}`;
export const KEYCLOAK_IDP_GRAFANA_URL = `${KC_IDP_GRAFANA_URL}`;

//IgniteQL URL
export const IGNITECONNEX_QL_URL = IGNITEQL_URL;
export const IGNITECONNEX_QL_EDITOR_URL = `${IGNITEQL_URL}/ui`;

//Api Base Url
export const IGNITE_API_BASE_URL = `${BASE_URL}`;
export const CORE_URL = `${CORE_API_URL}`;
export const AUX_URL = `${AUX_API_URL}`;
export const MONITOR_URL = `${MONITOR_API_URL}`;

//Onpremise Core API Endpoints
export const SIGNIN = `${CORE_URL}/api/v1/dashboard/accounts/signin`;
export const SIGNUP = `${CORE_URL}/api/v1/dashboard/accounts/signup`;
export const VERIFY = `${CORE_URL}/api/v1/dashboard/accounts/verify`;
export const TOKEN = `${CORE_URL}/api/v1/dashboard/accounts/token`;
export const SESSIONS = `${CORE_URL}/api/v1/dashboard/accounts/sessions/history`;
export const CHANGE_PASSWORD = `${CORE_URL}/api/v1/dashboard/accounts/changepassword`;
export const RESEND_VERIFICATION_EMAIL = `${CORE_URL}/api/v1/dashboard/accounts/reverify`;
export const RESET_PASSWORD = `${CORE_URL}/api/v1/dashboard/accounts/resetpassword`;
export const KEYCLOAK_SSO_TOKEN_URL = `${CORE_URL}/igcnx/dashboard/sso/token`;

export const DASHBOARD = `${CORE_URL}/api/v1/dashboard`;
export const APPS = `${CORE_URL}/api/v1/dashboard/apps`;
export const SPECS = `${CORE_URL}/api/v1/dashboard/specs`;
export const TEMPLATES = `${CORE_URL}/api/v1/dashboard/templates`;
export const PRODUCTS = `${CORE_URL}/api/v1/dashboard/products`;
export const MFA = `${CORE_URL}/api/v1/dashboard/accounts/settings/mfa`;
export const PRODUCT_PLANS = `${CORE_URL}/api/v1/product-management/products/plans`;
export const SSO = `${CORE_URL}/onpremise/sso`;
export const COLLABORATORS = `collaborators`;
export const TABS = `tabs`;
export const CHARTS = `charts`;

//Sandbox Endpoints
export const SANDBOX_LOGIN = `${AUX_URL}/igcnx/sandbox/user`;
export const SANDBOX_VALIDATE = `${AUX_URL}/igcnx/sandbox/user/validate`;
export const SANDBOX_CAPTCHA = `${AUX_URL}/igcnx/sandbox/forms/captcha`;

//AUX API Endpoints
export const PROXY_ENDPOINT = `api/v1/proxy`;
export const STATUS = `${AUX_URL}/api/v1/status`;
export const CONNECTORS = `${AUX_URL}/api/v1/connectors`;
export const PUBLIC_CONNECTORS = `https://exchange.igniteconnex.io/connectors`;

//Monitor API Endpoints
export const MONITOR = `${MONITOR_URL}/api/v1/monitor`;

//User Runtime API Endpoints-----Add conditions to export different routes depending on versions
export const FLOWS = `flows`;
export const RUNTIME_HEALTH_CHECK = `health-check`;
export const RUNTIME_3X_HEALTH_CHECK = `status/health`;
export const HTTP_STATUS_INSIGHTS = `insights/http-status`;
export const HTTP_ENDPOINTS_INSIGHTS = `insights/http-endpoints`;
export const HTTP_INBOUNDS_INSIGHTS = `insights/http-inbounds`;
export const HTTP_OUTBOUNDS_INSIGHTS = `insights/http-outbounds`;
export const HISTORY = `runtimedetails/audit-flow`;
export const RUNTIME_2X_VERIFICATION = `runtimedetails/runtimeVersion`;
export const RUNTIME_3X_VERIFICATION = `registration/verify`;

//Services Url
export const DESIGNER_URL = `https://designer.cgignite.io/`;
export const EXPLORER_URL = `https://jsonata.cgignite.io/`;
export const IGNITE_DOCS = DOCS_URL;
export const HOME_URL = `https://www.cgignite.com/`;
export const ARTICLES_URL = `https://www.cgignite.com/docs/categories/getting-started`;
export const SUPPORT_URL = `https://www.cgignite.com/support`;
export const CONTACT_URL = `https://www.cgignite.com/contact-us`;
export const TRYNOW_URL = `https://www.cgignite.com/trynow`;
export const DEPLOY_TO_AZURE_PUBLIC = `https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2FCybergroup-Research%2Fignite-runtime-image%2Fmaster%2Fazure-app-service-dockerhub-public-image.json`;
export const DEPLOY_TO_AZURE_PRIVATE = `https://portal.azure.com/#create/Microsoft.Template/uri/https%3A%2F%2Fraw.githubusercontent.com%2FCybergroup-Research%2Fignite-runtime-image%2Fmaster%2Fazure-app-service-docker-private-image.json`;
export const DEPLOY_TO_AZURE_BASE_URL = `https://portal.azure.com/#create/Microsoft.Template/uri`;
export const DOCKER_FOR_WINDOWS = `https://hub.docker.com/editions/community/docker-ce-desktop-windows`;
export const DOCKER_FOR_MAC = `https://hub.docker.com/editions/community/docker-ce-desktop-mac`;
export const DOCKER_DESKTOP = `https://www.docker.com/products/docker-desktop`;
export const DOWNLOAD_POSTGRES = `https://www.postgresql.org/download/`;
export const TRUST_URL = "https://trust.igniteconnex.com/";
export const STATUS_URL = "https://status.igniteconnex.com/";

//Frontend Page Urls
export const HOST_NAME = `${host}`;
export const HOST_URL = `${protocol}//${host}${path}#`;
export const HOME_PAGE_URL = `${protocol}//${host}${path}#/apps`;
export const SIGNIN_PAGE_URL = `${protocol}//${host}${path}#/login`;
export const PROFILE_PAGE_URL = `${protocol}//${host}${path}#/profile`;
export const STATUS_PAGE_URL = `${protocol}//${host}${path}#/status`;

// IDP Account Console URL
export const KC_IDP_PERSONAL_PAGE_URL = `account/#/personal-info`;
export const KC_IDP_AUTHENTICATION_PAGE_URL = `account/#/security/signingin`;
export const KC_IDP_SESSIONS_PAGE_URL = `account/#/security/device-activity`;

// Dashboard Sidenav URL
export const SIDENAV_EXCHANGE = "https://exchange.igniteconnex.io";
export const SIDENAV_STATUS = "https://status.igniteconnex.io";
export const CONSOLE_APP_URL = CONSOLE_URL;
