import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import { PageLoader } from "./components/common/Loaders";
import Login from "./views/login/Login";
import OAuthLogin from "./views/login/OAuthLogin";
import TheLayout from "./layout/TheLayout";
import Logout from "./views/login/Logout";
import Sandbox from "./views/sandbox/Sandbox";
import SandboxValidate from "./views/sandbox/components/validate"

export default class Dashboard extends React.Component {
  render() {
    return (
      <HashRouter>
        <React.Suspense fallback={PageLoader}>
          <Switch>
            <Route
              exact
              path="/sandbox"
              name="Sandbox Login"
              render={(props) => <Sandbox {...props} />}
            />
            <Route
              exact
              path="/sandbox/validate"
              name="Sandbox Validate"
              render={(props) => <SandboxValidate {...props} />}
            />
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/logout"
              name="Logout Page"
              render={(props) => <Logout {...props} />}
            />
            <Route
              exact
              path="/oauth/:token"
              name="Oauth"
              render={(props) => <OAuthLogin {...props} />}
            />
            <Route
              path="/"
              name="home"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}
