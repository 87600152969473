import React from 'react';
import { CSpinner } from '@coreui/react';

export const PageLoader = () => {
  return (
    <CSpinner
      color="primary"
      variant="grow"
      style={{ width: '4rem', height: '4rem', position: "fixed", bottom: "50%", left: "50%", zIndex: "10" }}
    />
  )
};

export const ModalLoader = () => {
  return (
    <CSpinner
      color="primary"
      style={{ width: '10', height: '10', position: "absolute", bottom: "50%", left: "47%", zIndex: "8" }}
    />
  )
};