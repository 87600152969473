import React from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CButton } from '@coreui/react';
import { KEYCLOAK_SSO_TOKEN_URL } from 'src/utils/api/endpoints';
import { PageLoader } from '../../components/common/Loaders';
import API from '../../utils/api/http-client';
import Auth from '../../user/auth';
import { Redirect } from 'react-router-dom';

export default class OAuthLogin extends React.Component {
  constructor(props) {
    super(props);
    let isAuthenticated = Auth.isAuthenticated();
    this.state = {
      isAuthenticated,
      loading: false,
      failed: false,
      error: false,
    }
  };

  async componentDidMount() {
    document.title = 'Logging in..';
    this.setState({ loading: true });
    let token = this.props.match.params.token;
    if (token) {
      if (token === 'error') {
        this.setState({
          loading: false,
          error: true,
        });
      } else {
        let response = await API.getAsync(`${KEYCLOAK_SSO_TOKEN_URL}/${token}`);
        if (response.status === 200) {
          await Auth.handleOAuth(response);
          this.setState({
            isAuthenticated: Auth.isAuthenticated()
          });
        }
        else {
          this.setState({
            loading: false,
            failed: true,
          });
        }
      }
    }
  };

  redirectToLogin = () => {
    this.setState({
      failed: true,
    });
  };

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to="/home" />
    }
    if (this.state.failed) {
      return <Redirect to="/login" />
    }
    return (
      <div>
        {this.state.loading && <PageLoader />}
        {this.state.error ? (
          <div className="c-app c-default-layout flex-row align-items-center">
            <CContainer>
              <CRow className="justify-content-center">
                <CCol xs="12" md="8">
                  <CCard>
                    <CCardBody>
                      <h1 className="text-danger">Single Sign-On Error</h1>
                      <p className="text-muted">We could not log this user into IgniteConnex Dashboard. Please try again.</p>
                      <p>If you continue to see this error, please visit <a href = "https://support.igniteconnex.com/" target="_blank" rel="noreferrer">IgniteConnex Support</a> </p>
                      <CButton type="submit" color="primary" onClick={this.redirectToLogin} >Back to Login</CButton>
                    </CCardBody>
                  </CCard>
                </CCol>
              </CRow>
            </CContainer>
          </div>
        ) : null}
      </div>
    )
  }
}