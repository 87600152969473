import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  CHeader, CToggler, CHeaderBrand, CHeaderNav, CDropdown, CTooltip,
  CDropdownToggle, CDropdownMenu, CDropdownItem, CSubheader, CBreadcrumb, CBreadcrumbItem,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import Auth from '../user/auth';
import { userDetails } from '../user/controls';
import { IGNITE_DOCS } from '../utils/api/endpoints';

const TheHeader = (props) => {
  const dispatch = useDispatch()
  function decodeURL(str) {
    // Replace "%20" with a space character
    str = str.replace(/%20/g, ' ');

    // Capitalize the first letter of each word
    str = str.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });

    return str;
  };

  const sidebarShow = useSelector(state => state.sidebarShow)
  const pathnames = window.location.hash.split('/');
  pathnames.shift();
  const paths = pathnames.map(item => decodeURL(item));


  const AppBreadcrumb = () => {
    return (
      <CBreadcrumb style={{ marginBottom: "-10px", border: "0px" }}>
        {paths.map((name, index) => {
          const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
          const activeTab = index === pathnames.length - 1;
          return (
            <CBreadcrumbItem key={name} active={activeTab}>
              {activeTab ? name : <Link to={routeTo}>{decodeURL(name)}</Link>}
            </CBreadcrumbItem>
          );
        })}
      </CBreadcrumb>
    );
  };

  const toggleSidebar = () => {
    const val = [true, 'responsive'].includes(sidebarShow) ? false : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  };

  const toggleSidebarMobile = () => {
    const val = [false, 'responsive'].includes(sidebarShow) ? true : 'responsive'
    dispatch({ type: 'set', sidebarShow: val })
  };

  const changePassword = async () => {
    await Auth.changePassword(true);
  };

  const user = userDetails();
  const email = user.email;
  const username = `${user.first_name} ${user.last_name}`;
  const avatar = user.initials;

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/home">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
      </CHeaderNav>
      <CHeaderNav className="px-3">
        <CTooltip content={'IgniteConnex Docs'}>
          <a href={IGNITE_DOCS} target="_blank" rel="noreferrer" >
            <CIcon
              name="cil-book"
              style={{
                cursor: "pointer",
                transition: "color 0.3s",
                color : "grey"
              }}
              onMouseEnter={(e) => {
                e.target.style.color = "#007bff"; // Change the color on hover
              }}
              onMouseLeave={(e) => {
                e.target.style.color = "grey"; // Reset the color on mouse leave
              }}
            />
          </a>
        </CTooltip>
        <CDropdown inNav className="c-header-nav-items mx-2" direction="down" >
          <CDropdownToggle className="c-header-nav-link" caret={false}>
            <div className="c-avatar" style={{ backgroundColor: '#DAE3EB' }}>
              <div className="c-avatar-img text-center" style={{ color: "black" }}><strong>{avatar}</strong></div>
            </div>
          </CDropdownToggle>
          <CDropdownMenu className="pt-0" placement="bottom-end">
            <CDropdownItem header tag="div" color="light" className="text-center" > <strong>Profile</strong></CDropdownItem>
            <CDropdownItem onClick={changePassword}>
              <CIcon name="cil-user" className="mfe-2" />
              <span>
                {username}
                <br />
                <span className="small">{email} </span>
              </span>
            </CDropdownItem>
            <CDropdownItem header tag="div" color="light" className="text-center" >
              <strong>Account</strong>
            </CDropdownItem>
            <CDropdownItem onClick={() => Auth.logout()}>
              <CIcon name="cil-lock-locked" className="mfe-2" />Log Out
            </CDropdownItem>
          </CDropdownMenu>
        </CDropdown>
      </CHeaderNav>
      <CSubheader className="px-3 justify-content-between">
        {/* <CBreadcrumbRouter className="border-0 c-subheader-nav m-0 px-0 px-md-3" routes={routes} /> */}
        <div>
          {AppBreadcrumb()}
        </div>
      </CSubheader>
    </CHeader>
  )
};

export default TheHeader;