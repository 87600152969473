import CIcon from "@coreui/icons-react";
import { STATUS_URL, TRUST_URL, CONSOLE_APP_URL } from "../utils/api/endpoints";

const navigation = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/home",
    icon: <CIcon name="cil-speedometer" customClasses="c-sidebar-nav-icon" />,
    title:
      "Dashboard serves as a centralized hub for accessing blogs, docs, the connector library, and monitoring the status of all igniteconnex services.",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Services"],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Apps",
    to: "/apps",
    icon: "cil-library",
    title:
      "Apps offer a platform for creating flows using the 2.0.x runtimes. Access the editor to design and build custom integrations by leveraging connectors from the library.",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Products",
    to: "/products",
    icon: "cil-layers",
    title:
      "Digital Product lets you create multiple apps for building flows and integrations. It also provisions the comprehensive IgniteConnex Identity Platform to efficiently manage users, while leveraging the robust Observe Platform to gain valuable insights through visualizing project data, accessing runtime logs, and tracing critical information",
  },
  {
    _tag: "CSidebarNavItem",
    name: "API Specs",
    to: "/specs",
    icon: "cil-notes",
    title:
      "API Specifications provide users with a powerful toolset for effectively managing their APIs through the OpenAPI Specification (OAS). With OAS, effortlessly define, document, and govern APIs, promoting seamless integration and collaboration across projects",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Templates",
    to: "/templates",
    icon: "cil-file",
    title:
      "Templates are designed to enhance productivity by offering reusable Igniteconnex flows. The pre-built templates can be effortlessly imported into any project, allowing users to efficiently incorporate and reuse specific features. In addition, users can create their own templates and use them across the projects.",
  },
  {
    _tag: "CSidebarNavTitle",
    _children: ["Explore"],
  },
  {
    _tag: "CSidebarNavItem",
    name: `Exchange`,
    to: "/exchange",
    icon: "cil-apps",
    title:
      "The Exchange is a robust platform designed to facilitate seamless integration between systems. It offers a diverse connector library that empowers you to build integrations effortlessly.",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Status",
    href: `${STATUS_URL}`,
    target: "_blank",
    icon: "cil-chart-line",
    title:
      "The Status feature provides real-time updates on the operational status of all igniteconnex services. This keeps you informed about the current state of igniteconnex services with instant visibility into any potential disruptions or maintenance activities",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Trust",
    href: `${TRUST_URL}`,
    target: "_blank",
    icon: "cil-shield-alt",
    title:
      "Trust offers valuable insights into the trustworthiness of Igniteconnex. Leveraging the capabilities of Vanta, a secutiry and compliance platform, Trust delivers reliable assessments and evaluations. You can gain confidence in Igniteconnex's trustworthiness through the comprehensive trust report, backed by the robust capabilities of Vanta.",
  },
  // {
  //   _tag: "CSidebarNavItem",
  //   name: "Console",
  //   href: `${CONSOLE_APP_URL}`,
  //   target: "_blank",
  //   icon: "cilExternalLink",
  //   title:
  //     "Welcome to IgniteConnex Platform. We have got some really exiciting updates for you and can't wait for you try them out.",
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: 'Platform Health',
  //   href: `${SIDENAV_STATUS}`,
  //   target: '_blank',
  //   icon: 'cil-chart-pie',
  // },
  // {
  //   _tag: 'CSidebarNavItem',
  //   name: `Ignite QL`,
  //   to: '/igniteQL',
  //   icon: 'cil-filter',
  // }
];

export default navigation;
