import { CCol, CRow, CImg, CCard, CCardBody } from "@coreui/react";
// import ReCAPTCHA from "react-google-recaptcha";
// import CIcon from "@coreui/icons-react";
// import { Col, Row, Form } from 'react-bootstrap';
// import { Col, Row, Form } from 'react-bootstrap';
import React from "react";
import API from "../../../utils/api/http-client";
// import IgniteConnexIconColor from "../../../assets/images/ignite-icon-full-orange.png";
import BackgroundImageSandbox from "../../../assets/images/backgorund-image-sandbox.png";
import { SANDBOX_VALIDATE } from "src/utils/api/endpoints";
import { PageLoader } from "../../../components/common/Loaders";

export default class Sandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      processing: false,
      success: false,
      showCard: false,
    };
  }


  checkValidation = async (token) => {
    this.setState({ loading: true });
    let response = await API.customCall(`${SANDBOX_VALIDATE}`, { 'authorization': `Bearer ${token}` })
    if (response) {
      this.setState({
        showCard: true,
        loading: false,
        success: response.status === 200
      });
    };
  };

  async componentDidMount() {
    let urlParams = new URLSearchParams(this.props.location.search);
    let token = urlParams.get("token");
    if (token) {
      this.checkValidation(token);
    }
  };

  render() {
    return (
      <div style={{ overflow: "hidden", height: "100vh" }}>
        {this.state.loading ? <PageLoader /> : null}
        {this.state.showCard &&
          <>
            <CCard
              className="px-0"
              style={{
                height: "100%",
                minHeight: "100vh",
                backgroundImage: `url(${BackgroundImageSandbox})`,
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
              }}
            >
              <CCardBody
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CRow style={{ alignItems: "center" }}>
                  <CCol
                    lg="12"
                    md="12"
                    className="d-flex flex-column justify-content-center"
                  >
                    <CCard style={{ width: "65rem" }}>
                      <CCardBody className="d-flex flex-column align-items-center">
                        <CRow className="ml-1 mb-4">
                          <CImg
                            src='https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-tall-black.svg'
                            className="c-sidebar-brand-full"
                            name="logo-full"
                            alt="Ignite"
                            height={"65rem"}
                          />
                        </CRow>
                        <CRow>
                          <CCol className="text-center mb-4">
                            {this.state.success ? (
                              <>
                                <h3 classname="mb-3">Almost There!</h3>
                                <h5 className="text-muted mt-3">
                                  Thank you for verifying your account.
                                </h5>
                                <h5 className="text-muted">
                                  Check your inbox in a few minutes for a link to
                                  get started.
                                </h5>
                                <p className="text-muted h6">
                                  Please follow the instructions in the email for the next steps.
                                </p>
                                <p className="text-muted h6">
                                  Contact us at <a href="https://support.igniteconnex.com" rel="noreferrer" target="_blank">IgniteConnex Support</a> if you have any issues or need help.
                                </p>
                              </>
                            ) : (
                              <>
                                <h5 className="text-muted mt-3">
                                  Oh no, Something Went Wrong
                                </h5>
                                <p className="text-muted h6">
                                  We are unable to process your request at this
                                  time.
                                </p>
                                <p className="text-muted h6">
                                  Contact us at <a href="https://support.igniteconnex.com" rel="noreferrer" target="_blank">IgniteConnex Support</a> if you have any issues or need help.
                                </p>
                              </>
                            )}
                          </CCol>
                        </CRow>
                      </CCardBody>
                    </CCard>
                  </CCol>
                </CRow>
              </CCardBody>
            </CCard>
          </>}

      </div>
    );
  }
}
