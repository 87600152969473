import axios from 'axios';
import { IGCNX_IDENTITY_ACCESS_TOKEN } from '../constants';
import { getLocalItem } from '../storage'
import { AUX_URL, PROXY_ENDPOINT } from './endpoints'

class API {

  //Checks if the Token is present or not (User Logged in or not)
  getHeaders = () => {
    let token = getLocalItem(IGCNX_IDENTITY_ACCESS_TOKEN);
    if (token) {
      return {
        'Authorization': `Bearer ${token}`,
        'Access-Control-Allow-Origin': '*'
      };
    } else {
      return {}
    }
  };

  //Generic Method to make a GET API request
  getAsync = async (url) => {
    try {
      let response = await axios(url, {
        method: 'GET',
        headers: this.getHeaders()
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  //Generic Method to make a POST API request
  postAsync = async (url, data) => {
    try {
      let response = await axios(url, {
        method: 'POST',
        headers: this.getHeaders(),
        data: data
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  //Generic Method to make a PUT API request
  putAsync = async (url, data) => {
    try {
      let response = await axios(url, {
        method: 'PUT',
        headers: this.getHeaders(),
        data: data
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  //Generic Method to make a PUT API request
  patchAsync = async (url, data) => {
    try {
      let response = await axios(url, {
        method: 'PATCH',
        headers: this.getHeaders(),
        data: data
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  //Generic Method to make a DELETE API request
  deleteAsync = async (url) => {
    try {
      let response = await axios(url, {
        method: 'DELETE',
        headers: this.getHeaders(),
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  customCall = async (url, headers, method, data) => {
    try {
      let response = await axios(url, {
        method: method || 'GET',
        headers: headers || this.getHeaders(),
        data: data || {}
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };
  
  Proxy = async (data) => {
    try {
      let response = await axios(`${AUX_URL}/${PROXY_ENDPOINT}`, {
        method: 'POST',
        data
      });
      return response;
    } catch (err) {
      return this.handleErrors(err);
    }
  };

  handleErrors = async (err) => {
    if (err.response) {
      return err.response;
    } else if (err.request) {
      return err.request;
    } else {
      return err;
    }
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default new API();