import React from 'react';
import { CContainer, CRow, CCol, CCard, CCardBody, CButton } from '@coreui/react';
import { PageLoader } from '../../components/common/Loaders';
import Auth from '../../user/auth';
import { Redirect } from 'react-router-dom';
import { userSession } from 'src/user/controls';

export default class Logout extends React.Component {
    constructor(props) {
        super(props);
        let isAuthenticated = Auth.isAuthenticated();
        this.state = {
            isAuthenticated,
            loading: false,
            failed: false,
            error: false,
        }
    };

    async componentDidMount() {
        document.title = 'Logged Out';
        this.handleCleanStorage();
    };

    handleCleanStorage = async () => {
        const authenticated = userSession(true);
        this.setState({ isAuthenticated: authenticated });
    };

    redirectToLogin = () => {
        this.setState({
            failed: true,
        });
    };

    render() {
        // if (this.state.isAuthenticated) {
        //     return <Redirect to="/home" />
        // }
        if (this.state.failed) {
            return <Redirect to="/login" />
        }
        return (
            <div>
                {this.state.loading && <PageLoader />}
                <div className="c-app c-default-layout flex-row align-items-center">
                    <CContainer>
                        <CRow className="justify-content-center">
                            <CCol xs="12" md="8">
                                <CCard>
                                    <CCardBody>
                                        <h1 className="text-success">Logout Successful</h1>
                                        <p className="text-muted">We have logged you out of IgniteConnex Dashboard.</p>
                                        <CButton type="submit" color="primary" onClick={this.redirectToLogin} >Sign in Again</CButton>
                                    </CCardBody>
                                </CCard>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        )
    }
}