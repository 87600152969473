import API from '../utils/api/http-client'
import { TOKEN, SIGNIN, PROFILE_PAGE_URL, KEYCLOAK_LOGOUT_URL, KEYCLOAK_IDP_CLIENT_ID } from '../utils/api/endpoints';
import { userTokens, userRoles, userSessionTime, userDetails, userInfo } from './controls';
import { getLocalItem, setLocalItem } from 'src/utils/storage';
import { IGCNX_IDENTITY_ACCESS_TOKEN, IGCNX_IDENTITY_SESSION_DATA, LOCAL_TOKEN_EXPIRY } from 'src/utils/constants';
class Auth {
  constructor() {
    this.authenticated = false;
  };

  isAuthenticated() {
    let token = userTokens();
    let moment = new Date().getTime();
    let expiry = userSessionTime();
    if (token) {
      if (expiry < moment) {
        this.logout();
      } else {
        this.authenticated = true;
      }
    }
    return this.authenticated;
  };

  login = async (email, password) => {
    let credentials = {
      "email": email,
      "password": password
    }
    let response = await API.postAsync(`${SIGNIN}`, credentials);
    if (response && response.status === 200) {
      if (response.data.mfa_enabled) {
        return response;
      }
      if (response.data.access_token) {
        this.saveToken(response.data.access_token);
      }
    }
    return response;
  };

  verify = async (data) => {
    let response = await API.postAsync(`${TOKEN}`, data);
    if (response && response.status === 200) {
      if (response.data.access_token) {
        this.saveToken(response.data.access_token);
      }
    }
    return response;
  };

  saveToken = (response) => {
    const token = JSON.parse(atob(response.access_token.split('.')[1]));
    const expiry = response.expires_in * 1000;

    setLocalItem(IGCNX_IDENTITY_SESSION_DATA, JSON.stringify(response));
    setLocalItem(LOCAL_TOKEN_EXPIRY, new Date().getTime() + expiry);
    userRoles(token.resource_access[KEYCLOAK_IDP_CLIENT_ID].roles || ["ignite-user"]);

    let requirements = {
      "iss": token.iss
    };
    userInfo(JSON.stringify(requirements));

    // set data for profile page
    userDetails(JSON.stringify({
      "first_name": token.given_name,
      "last_name": token.family_name,
      "email": token.email,
      "company": token.company || ""
    }));

    this.authenticated = true;

    // set automatic logout functionality on token expiry
    setTimeout(this.logout, expiry - 300000);
  };

  handleOAuth = async (response) => {
    // Save entire keycloak token
    setLocalItem(IGCNX_IDENTITY_ACCESS_TOKEN, response.data.identity.access_token);
    this.saveToken(response.data.identity);
    return true;
  };

  logout = async () => {
    this.authenticated = false;
    let url = KEYCLOAK_LOGOUT_URL;
    let keycloakContext;
    if (getLocalItem(IGCNX_IDENTITY_SESSION_DATA)) {
      keycloakContext = JSON.parse(getLocalItem(IGCNX_IDENTITY_SESSION_DATA))
    };
    if (keycloakContext && keycloakContext.id_token) {
      url = `${url}&id_token_hint=${keycloakContext.id_token}`
    }
    window.location.replace(url);
  };

  changePassword = async (redirect) => {
    if (redirect) {
      window.location.replace(PROFILE_PAGE_URL);
    }
  };
};

// eslint-disable-next-line import/no-anonymous-default-export
export default new Auth();
