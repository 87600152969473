import React from 'react';

const Dashboard = React.lazy(() => import('../views/dashboard/Dashboard'));
const Apps = React.lazy(() => import('../views/apps/Apps'));
const Products =React.lazy(() => import('../views/products/Products'));
const App = React.lazy(() => import('../views/apps/App'));
const Product = React.lazy(() => import('../views/products/Product'));
const ProductApp = React.lazy(() => import('../views/products/ProductApp'));
const Specs = React.lazy(() => import('../views/specs/Specs'));
const Spec = React.lazy(() => import('../views/specs/Spec'));
const Templates = React.lazy(() => import('../views/templates/Templates'));
const Template = React.lazy(() => import('../views/templates/Template'));
const Incident = React.lazy(() => import('src/components/Incident'));
const Exchange = React.lazy(() => import('../views/connectors/Exchange'));
const Details = React.lazy(() => import('../views/connectors/Details'));
const Action = React.lazy(() => import('../views/connectors/Action'));
const Profile = React.lazy(() => import('../views/settings/Profile'));
const Message = React.lazy(() => import('../views/settings/Messages'));
const Sandbox = React.lazy(() => import('../views/sandbox/Sandbox'))
const SandboxValidate = React.lazy(() => import('../views/sandbox/components/validate'))

const routes = [
  { path: '/', exact: true, name: '' },
  { path: '/home', exact: true, name: 'Home', component: Dashboard },
  { path: '/apps', exact: true, name: 'Apps', component: Apps },
  { path: '/apps/:id', exact: true, name: 'App Details', component: App },
  { path: '/products', exact: true, name: 'Product', component: Products },
  { path: '/products/:id', exact: true, name: 'Product Details', component: Product },
  { path: '/products/:product_id/:id', exact: true, name: 'Product App Details', component: ProductApp },
  { path: '/specs', exact: true, name: 'API Specs', component: Specs },
  { path: '/specs/:id', exact: true, name: 'API Spec Details', component: Spec },
  { path: '/templates', exact: true, name: 'Templates', component: Templates },
  { path: '/templates/:id', exact: true, name: 'Template Details', component: Template },
  { path: '/status/:id', exact: true, name: 'Incident', component: Incident },
  { path: '/profile', exact: true, name: 'Profile', component: Profile },
  { path: '/profile/inbox/:id', exact: true, name: 'Message', component: Message },
  { path: '/exchange', exact: true, name: 'Exchange', component: Exchange },
  { path: '/exchange/:id', exact: true, name: 'Details', component: Details },
  { path: '/exchange/:connector_id/:id', exact: true, name: 'Action', component: Action },
  { path: '/sandbox', exact: true, name: 'Sandbox', component: Sandbox },
  { path: '/sandbox/validate', exact: true, name: 'Sandbox Validate', component: SandboxValidate },
];

export default routes;