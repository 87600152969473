import { getLocalItem, setLocalItem, removeLocalItem } from '../utils/storage';
import { getInitials, toTitleCase } from '../utils/strings';
import { LOCAL_USER_THEME_PREFERENCE, LOCAL_USER_ROLES, LOCAL_USER_STATUS_PREFERENCE, LOCAL_TOKEN_EXPIRY, LOCAL_USER_DETAILS, LOCAL_INCIDENTS_DATA, USER_INFO, IGCNX_IDENTITY_ACCESS_TOKEN, IGCNX_IDENTITY_SESSION_DATA } from '../utils/constants';

export const userThemePreference = (theme) => {
  if (theme) {
    setLocalItem(LOCAL_USER_THEME_PREFERENCE, theme);
  } else {
    return getLocalItem(LOCAL_USER_THEME_PREFERENCE) || "light"
  }
};

export const userStatusPreference = (status) => {
  if (status) {
    setLocalItem(LOCAL_USER_STATUS_PREFERENCE, status);
  } else {
    return getLocalItem(LOCAL_USER_STATUS_PREFERENCE) || "latest"
  }
};

export const userTokens = (token) => {
  if (token) {
    setLocalItem(IGCNX_IDENTITY_ACCESS_TOKEN, token);
    return;
  } else {
    return getLocalItem(IGCNX_IDENTITY_ACCESS_TOKEN) || null
  }
};

export const userDetails = (details) => {
  if (details) {
    setLocalItem(LOCAL_USER_DETAILS, details);
  } else {
    let user = getLocalItem(LOCAL_USER_DETAILS);
    user = user ? JSON.parse(user) : {}
    return {
      first_name: user.first_name ? toTitleCase(user.first_name) : "Unknown",
      last_name: user.last_name ? toTitleCase(user.last_name) : "User",
      company: user.company ? toTitleCase(user.company) : "Company",
      email: user.email ? user.email.toLowerCase() : "user@domain.com",
      initials: `${getInitials(user.first_name ? user.first_name : "Unknown")}${getInitials(user.last_name ? user.last_name : "User")}`,
    }
  }
};

export const userSessionTime = (time) => {
  if (time) {
    setLocalItem(LOCAL_TOKEN_EXPIRY, time);
    return;
  } else {
    return getLocalItem(LOCAL_TOKEN_EXPIRY)
  }
};

export const userInfo = (data) => {
  if (data) {
    setLocalItem(USER_INFO, data);
    return;
  } else {
    return getLocalItem(USER_INFO)
  }
}

export const userRoles = (roles) => {
  if (roles) {
    setLocalItem(LOCAL_USER_ROLES, roles.toString());
    return;
  } else {
    let roles = getLocalItem(LOCAL_USER_ROLES) || "ignite-user"
    return roles.split(",");
  }
};

export const userPlatform = () => {
  return navigator.userAgent.includes('Windows') ? "windows" : navigator.userAgent.includes('Macintosh') ? "mac" : "unknown";
};

export const userSession = (destroy = false) => {
  if (destroy) {
    removeLocalItem(LOCAL_USER_ROLES);
    removeLocalItem(LOCAL_TOKEN_EXPIRY);
    removeLocalItem(LOCAL_USER_DETAILS);
    removeLocalItem(LOCAL_INCIDENTS_DATA);
    removeLocalItem(IGCNX_IDENTITY_SESSION_DATA);
    removeLocalItem(IGCNX_IDENTITY_ACCESS_TOKEN);
    removeLocalItem(USER_INFO);
    sessionStorage.clear();
  } else {
    let token_expiry = getLocalItem(LOCAL_TOKEN_EXPIRY);
    if (token_expiry > new Date().getTime()) {
      return true;
    } else {
      return false;
    }
  }
};