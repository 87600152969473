import React from 'react';
import { CFooter } from '@coreui/react';
import { getAppVersion } from '../utils/webapp'

const TheFooter = () => {
  return (
    <CFooter fixed={false} style={{justifyContent:"center"}}>
      <div>
        <a href="https://www.igniteconnex.com/" target="_blank" rel="noopener noreferrer">IgniteConnex Inc.</a>
        <span className="ml-1">&copy; {new Date().getFullYear()}</span>,
        <small className="ml-1">Version: {getAppVersion()}</small>
      </div>
    </CFooter>
  )
}

export default React.memo(TheFooter);