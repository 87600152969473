import {
  CCol,
  CRow,
  CImg,
  CCard,
  CCardBody,
  CInput,
  CButton,
  CFormGroup,
  CInputCheckbox,
  CLink,
  CTooltip,
} from "@coreui/react";
import restrictedEmailsDomains from "src/utils/restricted-emails";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import API from "../../utils/api/http-client";
// import IgniteConnexIconColor from "../../assets/images/ignite-icon-full-orange.png";
import BackgroundImageSandbox from "../../assets/images/backgorund-image-sandbox.png";
import { SANDBOX_LOGIN, SIGNIN_PAGE_URL, SANDBOX_CAPTCHA } from "src/utils/api/endpoints";
import { PageLoader } from "../../components/common/Loaders";
import { Discover, Launch, GraphUp } from "src/assets/icons/icons";
import { validatePasswordStrength } from "src/utils/strings";
// import axios from "axios";

export default class Sandbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      processing: false,
      captchaVerified: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      acceptPolicies: false,
      optInMarketing: false,
      passwordValidated: true,
      emailValidated: true,
      passwordMatch: true,
      errorMessage: "",
      statusDetails: "",
      apiResponse: true,
      responseData: {},
      site_key: '',
      captchaTokenValue: null,
      statusData: {
        200: {
          status: 200,
          title: "Thank you!",
          description: (
            <span style={{ m: "2" }}>
              <p>
                Your account and sandbox are being created, and you will receive
                an email shortly.
              </p>
              <p>
                {" "}
                Please follow the instructions in the email for the next steps.
              </p>
              <p style={{ fontSize: "12px" }}>
                {" "}
                Contact us at{" "}
                <a
                  href="https://support.igniteconnex.com"
                  rel="noreferrer"
                  target="_blank"
                >
                  IgniteConnex Support
                </a>{" "}
                if you have any issues or need help.{" "}
              </p>
            </span>
          ),
        },
        403: {
          status: 403,
          title: "Welcome back!",
          description:
            "You already have an existing account with IgniteConnex. You can log in here to access your account. Enjoy your IgniteConnex experience!",
        },
        404: {
          status: 404,
          title: "Oh No, Something went wrong!",
          description: "We are unable to process your request at this time.",
        },
      },
    };
  };

  componentDidMount() {
    document.title = "IgniteConnex Sandbox";
    this.getSiteKey();
  };

  validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
    // Extract the domain from the email address
    const emailParts = email.split('@');
    const domain = emailParts[1];

    // Check if the domain is in the list of blocked domains
    if (restrictedEmailsDomains.includes(domain)) {
      return false; // Block the email
    }

    // Check the email format using the regular expression
    return emailRegex.test(email);
  };

  getSiteKey = async () => {
    const captcha_response = await API.getAsync(`${SANDBOX_CAPTCHA}?key=site_key`);
    if (captcha_response.status === 200) {
      this.setState({ site_key: captcha_response.data.site_key })
    }
  }

  validateForm = () => {
    return (
      this.state.firstName &&
      this.state.lastName &&
      this.state.acceptPolicies &&
      this.state.email &&
      this.validateEmail(this.state.email) &&
      this.state.password &&
      validatePasswordStrength(this.state.password) &&
      this.state.confirmPassword &&
      validatePasswordStrength(this.state.confirmPassword) &&
      this.state.password === this.state.confirmPassword &&
      this.state.captchaVerified
    );
  };

  verifyRecaptcha = async (responseToken) => {
    const captcha_response = await API.getAsync(`${SANDBOX_CAPTCHA}?key=secret_key`);
    let secret_key = "";
    if (captcha_response.status === 200) {
      secret_key = captcha_response.data.secret_key;
    }
    // let secret_key = "6LefUBcmAAAAAK27EHmXVWkBtq5-d8mJe4PT8X1y";
    let response = await API.Proxy({
      url: `https://www.google.com/recaptcha/api/siteverify?secret=${secret_key}&response=${responseToken}`,
      method: 'POST',
    });
    if (response.data.success) {
      this.setState({
        captchaVerified: true,
      });
      this.validateForm();
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
    if (e.target.name === "email") {
      this.setState({ emailValidated: this.validateEmail(e.target.value) })
    }
    if (e.target.name === "password") {
      this.setState({ passwordValidated: validatePasswordStrength(e.target.value) })
    }
    if (e.target.name === "confirmPassword") {
      this.setState({ passwordMatch: this.state.password === e.target.value })
    }
  };

  addEmail = async () => {
    this.validateForm();
    let data = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      accept_policies: this.state.acceptPolicies,
      opt_in_marketing: this.state.optInMarketing,
    };
    this.setState({ loading: true });
    const response = await API.postAsync(`${SANDBOX_LOGIN}`, data);
    if (response) {
      this.setState({
        loading: false,
        apiResponse: false,
      });
      this.setState({
        responseData:
          this.state.statusData[response.status] || this.state.statusData[404],
      });
    }
    // this.verifyRecaptcha();
  };

  handleCheckboxChange = (e) => {
    this.setState({
      [e.target.name]: e.target.checked,
    });
  };

  handleButton = () => {
    window.location.href = SIGNIN_PAGE_URL;
  };

  render() {
    return (
      <div style={{ height: "100vh" }}>
        {this.state.loading ? <PageLoader /> : null}
        <CCard
          className="px-0"
          style={{
            height: "100%",
            minHeight: "100vh",
            backgroundImage: `url(${BackgroundImageSandbox})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // alignItems: "center",
            // display: "flex",
            // justifyContent: "center",
          }}
        >
          <CCardBody
            style={{
              marginTop: "1rem",
              flexWrap: "wrap",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CRow>
              <CCol>
                <CRow className=" ml-1 mb-5">
                  <CImg
                    src="https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-color-tall-black.svg"
                    name="logo-full"
                    alt="Ignite"
                    height={"65rem"}
                  />
                </CRow>
                <CRow>
                  <CCol className="mb-3">
                    {Discover}
                    <strong> Get Access </strong>
                    <p className="text-muted">
                      Enter your information and click "Start Building".
                    </p>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className="mb-3">
                    {Launch}
                    <strong> Validate Your Email </strong>
                    <p className="text-muted">
                      We'll send you an email validation link to confirm your
                      address.
                    </p>
                  </CCol>
                </CRow>
                <CRow>
                  <CCol className="mb-3">
                    {GraphUp}
                    <strong> Use our Getting Started Templates </strong>
                    <p className="text-muted">
                      Templates are reusable flow-based applications that allow
                      you to get started quickly with the basics.
                    </p>
                  </CCol>
                </CRow>
              </CCol>
              <CCol sm="9" lg="6" md="12" xxl="6">
                <CCard
                  style={{ width: "22rem" }}
                  className="flex-column justify-content-center"
                >
                  {this.state.apiResponse ? (
                    <>
                      {" "}
                      <CCardBody
                        className="d-flex flex-column align-items-center "
                        style={{ justifyContent: "center" }}
                      >
                        <CRow>
                          <strong>Explore IgniteConnex Possibilities</strong>
                        </CRow>
                        <CRow>
                          <CCol
                            xs="11"
                            sm="11"
                            md="11"
                            lg="11"
                            xxl="11"
                            className="d-flex flex-column ml-2"
                          >
                            <CInput
                              type="text"
                              name="firstName"
                              value={this.state.firstName}
                              onChange={this.onChange}
                              valid={this.state.firstName === "" ? false : true}
                              placeholder="First Name"
                              className="p-2 m-2"
                            />
                            <CInput
                              type="text"
                              name="lastName"
                              value={this.state.lastName}
                              valid={this.state.lastName === "" ? false : true}
                              onChange={this.onChange}
                              placeholder="Last Name"
                              className="p-2 m-2"
                            />
                            <CTooltip content="Enter a valid business email address.">
                              <CInput
                                type="email"
                                name="email"
                                value={this.state.email}
                                valid={this.state.email === "" ? false : this.state.emailValidated}
                                onChange={this.onChange}
                                placeholder="Your Email"
                                className="p-2 m-2"
                              />
                            </CTooltip>
                            {
                              this.state.emailValidated ? null : (
                                <div style={{ marginLeft: 10 }}>
                                  <span style={{ color: "red" }}>❌ Enter a valid Business Email ID</span>
                                </div>
                              )
                            }
                            <CInput
                              type="password"
                              name="password"
                              value={this.state.password}
                              valid={this.state.password === "" ? false : this.state.passwordValidated}
                              onChange={this.onChange}
                              placeholder="Password"
                              className="p-2 m-2"
                            />
                            {
                              this.state.passwordValidated ? null : (
                                <div style={{ marginLeft: 10 }}>
                                  <span style={{ color: "red" }}>❌ Password must contain at least 8 characters, including 1 uppercase letter, 1 lowercase letter,1 number and at least 1 special character.</span>
                                </div>
                              )
                            }
                            <CTooltip content="Enter the same password as before, for verification.">
                              <CInput
                                type="password"
                                name="confirmPassword"
                                value={this.state.confirmPassword}
                                valid={this.state.confirmPassword === "" ? false : this.state.passwordMatch}
                                onChange={this.onChange}
                                placeholder="Confirm Password"
                                className="p-2 m-2"
                              />
                            </CTooltip>
                            {
                              this.state.passwordMatch ? null : (
                                <div style={{ marginLeft: 10 }}>
                                  <span style={{ color: "red" }}>❌ Password do not match</span>
                                </div>
                              )
                            }
                            <CFormGroup style={{ margin: "auto -1.125rem" }}>
                              <CFormGroup className="m-1 ml-5">
                                <CInputCheckbox
                                  name="acceptPolicies"
                                  label="Accept Policies"
                                  checked={this.state.acceptPolicies}
                                  onChange={this.handleCheckboxChange}
                                />
                                <CLink
                                  href="https://www.igniteconnex.com/sandbox-end-user-license/"
                                  target="_blank"
                                >
                                  Accept Policies
                                </CLink>
                              </CFormGroup>
                              <CFormGroup className="m-1 ml-5">
                                <CInputCheckbox
                                  name="optInMarketing"
                                  label="Opt-In Marketing"
                                  checked={this.state.optInMarketing}
                                  onChange={this.handleCheckboxChange}
                                />
                                Opt-In Marketing
                              </CFormGroup>
                            </CFormGroup>
                            <CCol>
                              {this.state.site_key ?
                                <ReCAPTCHA
                                  className="m-2"
                                  // sitekey="6LefUBcmAAAAAM8sC6cnj2C3qrEJuIjbKAgOwRA1"
                                  sitekey={this.state.site_key}
                                  onChange={this.verifyRecaptcha}
                                /> : null}
                            </CCol>
                          </CCol>
                          <CCol className="text-center mt-2">
                            {this.validateForm() ? (
                              <CButton
                                style={{
                                  backgroundColor: "#22C5DB",
                                  borderRadius: "50px",
                                  width: "90%",
                                  color: "black",
                                  fontWeight: 500,
                                  margin: "0 auto",
                                }}
                                onClick={() => this.addEmail()}
                              >
                                Start Building
                              </CButton>
                            ) : (
                              <CButton
                                disabled
                                style={{
                                  backgroundColor: "#E3ECF5",
                                  borderRadius: "50px",
                                  width: "90%",
                                  color: "black",
                                  fontWeight: 500,
                                  margin: "0 auto",
                                }}
                              >
                                Start Building
                              </CButton>
                            )}
                          </CCol>
                        </CRow>
                      </CCardBody>{" "}
                    </>
                  ) : (
                    <CCardBody className="d-flex flex-column align-items-center">
                      <CRow>
                        <strong className="m-2 p-1 h4  text-muted  text-center">
                          {this.state.responseData.title}
                        </strong>
                      </CRow>
                      <CRow>
                        <p className="mt-3 text-muted text-center">
                          {this.state.responseData.description}
                        </p>
                      </CRow>
                      {this.state.responseData.status === 403 ? (
                        <CButton
                          color="primary"
                          style={{
                            borderRadius: "50px",
                            width: "100%",
                            maxWidth: "200px",
                            margin: "0 auto",
                          }}
                          onClick={() => this.handleButton()}
                        >
                          {" "}
                          Login{" "}
                        </CButton>
                      ) : null}
                    </CCardBody>
                  )}
                </CCard>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </div >
    );
  }
}
