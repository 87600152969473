import React from 'react';
import { Redirect } from 'react-router-dom';
import Auth from '../../user/auth';
import { PageLoader } from '../../components/common/Loaders';
import { KEYCLOAK_IDP_AUTH_URL } from '../../utils/api/endpoints';
import { getRandomState } from '../../utils/strings';

export default class Login extends React.Component {
  constructor(props) {
    super(props);
    let isAuthenticated = Auth.isAuthenticated();
    this.state = {
      isAuthenticated,
      loading: false,
    }
  };

  UNSAFE_componentWillMount() {
    document.title = 'Login';
  };

  componentDidMount = () => {
    this.loginWithKeycloak();
  }

  loginWithKeycloak = () => {
    let AUTH_URL = `${KEYCLOAK_IDP_AUTH_URL}&state=${getRandomState()}`
    window.open(AUTH_URL, '_self');
  };

  render() {
    if (this.state.isAuthenticated) {
      return <Redirect to="/home" />
    }
    return (
      <div>
        {this.state.loading ? <PageLoader /> : null}
      </div>
    )
  }
};