//Texts
export const EMPTY_TEXT = `—————`;

//Keys
export const LOCAL_USER_THEME_PREFERENCE = `IGCNX_USER_THEME_PREFERENCE`;
export const LOCAL_USER_STATUS_PREFERENCE = `IGCNX_USER_STATUS_VIEW_PREFERENCE`;
export const LOCAL_USER_DETAILS = `IGCNX_USER_DETAILS`;
export const LOCAL_USER_ROLES = `IGCNX_USER_ROLES`;
export const LOCAL_TOKEN_EXPIRY = `IGCNX_TOKEN_EXPIRY`;
export const LOCAL_INCIDENTS_DATA = `IGCNX_IGNITE_INCIDENTS_DATA`;
export const SESSION_RUNTIME_URL = `IGCNX_RUNTIME_URL`;
export const SESSION_RUNTIME_TOKEN = `IGCNX_RUNTIME_ACCESS_TOKEN`;
export const SESSION_RUNTIME_FLOWS = `IGCNX_RUNTIME_FLOWS_DATA`;
export const SESSION_RUNTIME_LOGS = `IGCNX_RUNTIME_LOGS_DATA`;
export const SESSION_TEMPLATE_FLOW_JSON = `IGCNX_TEMPLATE_FLOWS_DATA`;
export const USER_INFO = `IGCNX_USER_INFO`;
export const APP_MONITORING_TOKEN = `IGCNX_APP_MONITORING_TOKEN`;
export const APP_RUNTIME_TOKEN = `IGCNX_APP_RUNTIME_TOKEN`;
export const PRODUCTS_LIST = `IGCNX_PRODUCTS_LIST`;
export const PRODUCT_APPS_LIST = `IGCNX_PRODUCT_APPS_LIST`;
export const CURRENT_PRODUCT = `IGCNX_CURRENT_PRODUCT`;
export const SPECS_LIST = `IGCNX_SPECS_LIST`;
export const TEMPLATES_LIST = `IGCNX_TEMPLATES_LIST`;
export const CONNECTOR_ACTIONS_LIST = `IGCNX_CONNECTOR_ACTIONS_LIST`;
export const IGCNX_IDENTITY_ACCESS_TOKEN = `IGCNX_IDENTITY_ACCESS_TOKEN`;
export const IGCNX_IDENTITY_SESSION_DATA = `IGCNX_IDENTITY_SESSION_DATA`;

//HTTP Response Categories
export const HTTP_SUCCESS = [200, 201, 202, 204];
export const HTTP_REDIRECT = [300, 301, 302, 303, 307];
export const HTTP_CLIENT_ERROR = [400, 401, 402, 403, 404, 405, 406, 407, 408, 409, 410, 415];
export const HTTP_SERVER_ERROR = [500, 501, 502, 503, 504, 505];