//Validates an Email Address
export const validateEmail = (email) => {
  // eslint-disable-next-line
  let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validatePasswordStrength = (password) => {
  let reg = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
  return reg.test(password);
};

//Shorten a text to a limit
export const wrapSentence = (longSentence, length = 50) => {
  if (longSentence.length > length) {
    return longSentence.substring(0, length) + " ...";
  } else
    return longSentence;
};

//This fucntion converts a string to title case
export const toTitleCase = (title) => {
  return title.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  )
};

//This function converts a string to sentance case
export const toSentanceCase = (sentence) => {
  // eslint-disable-next-line
  return sentence.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c) { return c.toUpperCase() });
};

//Returns a Human Readabale Date
export const formatDate = (date = new Date()) => {
  let months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
  return date.getDate() + "-" + months[date.getMonth()] + "-" + date.getFullYear();
};

//Returns a Human Readabale Date
export const getLocalDateTime = (date = new Date()) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`;
};

//Returns a Human Readabale Date
export const getDateTimeFromTimestamp = (timestamp) => {
  let date = new Date(timestamp);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}, ${date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}`;
};

//App status string modifiers
export const getAppStatus = (status) => {
  return status;
};

export const getInitials = (word) => {
  return word.substr(0, 1).toUpperCase();
};

export const getRandomState = () => {
  return `${Math.random().toString(36).substr(2, 10)}${Math.random().toString(36).substr(2, 10)}${Math.random().toString(36).substr(2, 10)}`;
};

export const convertTimestampToLocalString = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleString("en-US", options).replace(" at", ",");
};

export const generateUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}