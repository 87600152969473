import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import {
  CCreateElement, CSidebar, CSidebarBrand, CSidebarNav, CSidebarNavDivider, CSidebarNavTitle, CSidebarMinimizer,
  CSidebarNavDropdown, CSidebarNavItem, CImg
} from '@coreui/react';
import API from '../../src/utils/api/http-client';
import { APPS } from '../../src/utils/api/endpoints';
import IgniteIconColor from '../assets/images/Ignite-Logo-Linear.svg';
import navigation from '../navigation/nav';

const TheSidebar = () => {
  const dispatch = useDispatch()
  const show = useSelector(state => state.sidebarShow)
  const [navs, setNavs] = React.useState(navigation);

  const checkApps = async () => {
    const response = await API.getAsync(`${APPS}`);
    if (response && response.data && response.data.length === 0) {
      setNavs(navigation.filter(item => item.to !== "/apps"));
    }
  };

  useEffect(() => {
    checkApps();
  }, [])


  return (
    <CSidebar
      style={{ "background": "#231F20" }}
      show={show}
      onShowChange={(val) => dispatch({ type: 'set', sidebarShow: val })}>
      <CSidebarBrand className="d-md-down-none" to="/home">
        <CImg
          src={IgniteIconColor}
          className="c-sidebar-brand-full"
          name="logo-full"
          alt="Ignite"
          height={35}
        />
        <CImg
          // src={IgniteIconWhiteI}
          src="https://cdn.igniteconnex.io/public/projects/igcnx/branding/igcnx-icon-white.svg"
          className="c-sidebar-brand-minimized"
          name="logo-negative"
          alt="Ignite"
          height={35}
        />
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navs}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  )
}

export default React.memo(TheSidebar);
